import axios from 'axios'
import {
  AddCustomer,
  Address,
  Customer,
  CustomersType,
  EditCustomerDetail,
} from '../models/customer_models'
import {AddProduct, EditProduct, Product, ProductsType} from '../models/product_models'
import {AddUser, User, UsersType, RoleWiseUsers, UserRoles} from '../models/user_models'
import {supabase} from './SupabaseConnect'
import {toast} from 'react-toastify'
import {CallHistory, FollowupCall, PendingCall} from '../models/calls_models'
import {CreateOrder, CreateReturnOrder, EditOrder} from '../models/order_models'
import {Payments} from '../models/payment_model'
import {
  AccountType,
  AddCompany,
  AddCourierCompany,
  Company,
  CourierCompany,
  CourierCompanyWithBarcode,
  EditCourierCompany,
  Leave,
} from '../models/setting_models'
import {api_url} from '../../utils'
import {format} from 'date-fns'

class SupabaseMethods {
  // constructor() {}

  async userLogin(
    email: string,
    password: string
    // setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<any> {
    try {
      const {data, error} = await supabase.auth.signInWithPassword({
        email,
        password,
      })
      console.log('error from login ->', error)
      if (data.user) {
        const {data: user, error} = await supabase
          .from('users')
          .select('*')
          .eq('user_id', data.user.id)
          .single()
        console.log('error from user ->', error)
        const {data: role} = await supabase
          .from('user_roles')
          .select('name')
          .in('id', JSON.parse(user.role))
        let user_data = {
          ...user,
          role: role?.map((r) => {
            return r.name
          }),
        }
        console.log('User ->', user_data)
        return user_data
      } else {
        return error?.message
      }
    } catch (error) {
      console.log('internal server error ->', error)
      return error
    }
  }

  async getCustomerCallList() {
    try {
    } catch (error) {}
    return
  }

  async customerCallDetails(id: string): Promise<any> {
    try {
      console.log('customer ->', id)

      const {data, error} = await supabase.from('orders').select('*,order_items(*)').eq('id', id)

      console.log('from customers order ->', data)

      if (data) return data
      else toast.error(error.message)
    } catch (error) {
      console.log('internal server error from get customer all orders ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getCustomerOrders(customer_id: string, from: string): Promise<any> {
    try {
      let orders: any = []
      if (from === 'orders') {
        const {data, error} = await supabase
          .from('orders')
          .select(
            '*,customers(*),sales_man:sales_person_id(id,name),orders_courier_provider:courier_provider_id(id,name),order_items(*,products(name,code,image)),customer_address(*)'
            // '*,customers(*,users!customers_sales_person_id_fkey(id,name)),courier_providers!orders_courier_provider_id_fkey(id,name),customer_address(address,pincode,city,district,state),order_items(*,products(name,code,image))'
          )
          .eq('customer_id', customer_id)
          .limit(5)
          .order('created_at', {ascending: false})

        console.log('on customer select ->', error)
        orders = data
      }
      if (from === 'followup') {
        const {data} = await supabase
          .from('orders')
          .select(
            // '*,customers(users!customers_sales_person_id_fkey(id,name)),courier_providers!orders_courier_provider_id_fkey(id,name),order_items(*,products(name,code,image))'
            '*,customers(*),sales_man:sales_person_id(id,name),order_courier_provider:courier_provider_id(id,name,tracking_url),return_courier_provider:return_courier_provider_id(id,name,tracking_url),customer_address(address,pincode,city,district,state),order_items(*,products(name,code,image)),order_payment_method:payment_method(type),return_payment:return_payment_method(type)'
          )
          .eq('customer_id', customer_id)
          .order('followup_date', {ascending: true})

        // console.log('on customer select ->', error)
        orders = data
      }
      return orders
    } catch (error) {
      console.log('internal server error from get customer all orders ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getCustomerCallHistory(customer_id: string): Promise<any> {
    const {data, error} = await supabase
      .from('followups')
      .select('*,caller:caller_id(name),order:orders(order_code)')
      .eq('status', 'completed')
      .eq('customer_id', customer_id)
      .order('created_at', {ascending: false})

    if (data) return data
    else toast.error(error.message)
  }

  async getAllPayments(searchValue: string, type: string, page: number): Promise<any> {
    try {
      const res = await axios.post(`${api_url}/payment_filter`, {searchValue, type, page})

      return res.data
    } catch (error) {
      console.log('internal server error from get all payments ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getAllCourierCompany(): Promise<CourierCompany[] | undefined> {
    try {
      const {data, error} = await supabase
        .from('courier_providers')
        .select('*')
        .is('deleted_at', null)

      let company_list = data?.map((company: any) => {
        return {
          value: company.id,
          label: company.name,
          e_biller_id: company.e_biller_id,
          tracking_url: company.tracking_url,
          followup_days: company.followup_days,
        }
      })

      if (data) return company_list
      else toast.error(error.message)
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getTotalCallsHistory(searchValue: string) {
    const res = await axios.post(`${api_url}/call_history_filter`, {
      searchValue,
      type: 'COUNT_CALLS',
      page: 0,
    })
    return res.data.data.total_orders
  }

  async getAllCallsHistory(searchValue: string, page: number): Promise<CallHistory[] | undefined> {
    try {
      const res = await axios.post(`${api_url}/call_history_filter`, {
        searchValue,
        type: 'GET_CALLS',
        page,
      })
      return res.data.data
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async addNewOrder(order: CreateOrder): Promise<any> {
    const {
      customer_id,
      sales_person_id,
      created_by,
      address,
      city,
      district,
      state,
      taluka,
      village,
      at_post,
      pincode,
      address_id,
      tracking_id,
      payment_mode,
      payment_method,
      total_amount,
      paid_amount,
      payment_status,
      order_status,
      courier_provider_id,
      courier_weight,
      follow_up_call,
      order_items,
      payment,
      shipping_charges,
      order_date,
      remark,
    } = {...order}

    const order_detail = {
      customer_id,
      sales_person_id,
      created_by,
      address,
      city,
      district,
      state,
      pincode,
      taluka,
      village,
      at_post,
      payment_mode,
      payment_method,
      payment_status,
      order_status,
      courier_provider_id,
      courier_weight,
      follow_up_call,
      address_id,
      tracking_id,
      total_amount,
      paid_amount,
      shipping_charges,
      order_date,
      remark,
    }

    const data = await axios.post(`${api_url}/create_order`, {
      data: {order_detail, order_items, payment},
    })

    // console.log('data from create order ->', data)

    if (data.data.statusCode === 201) {
      toast.success(data.data.message)
      return data.data
    } else {
      toast.error(data.data.message)
    }
  }

  async editOrder(order: EditOrder): Promise<any> {
    const {
      id,
      customer_id,
      sales_person_id,
      address,
      city,
      district,
      state,
      taluka,
      village,
      at_post,
      pincode,
      edited_by,
      edited_at,
      address_id,
      tracking_id,
      payment_mode,
      payment_method,
      total_amount,
      paid_amount,
      payment_status,
      order_status,
      courier_provider_id,
      courier_weight,
      shipping_charges,
      order_items,
      payment,
      remark,
    } = {...order}

    const order_detail = {
      id,
      customer_id,
      sales_person_id,
      address,
      city,
      district,
      state,
      taluka,
      village,
      at_post,
      pincode,
      edited_by,
      edited_at,
      address_id,
      tracking_id,
      payment_mode,
      payment_method,
      total_amount,
      paid_amount,
      payment_status,
      order_status,
      courier_provider_id,
      courier_weight,
      shipping_charges,
      remark,
    }

    const data = await axios.post(`${api_url}/edit_order`, {
      data: {order_detail, order_items, payment},
    })

    // console.log('data from create order ->', data)

    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      console.log('from edit order ->', data.data.data)
      return data.data.data
    } else {
      toast.error(data.data.message)
    }
  }

  async getOrders(searchValue: string, type: string, page: number): Promise<any> {
    try {
      const res = await axios.post(`${api_url}/common_order_filter`, {
        searchValue,
        type,
        page,
      })
      return res.data.data
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getAllReturnOrders() {
    try {
    } catch (error) {}
    return
  }

  async createReturnOrder(return_order: CreateReturnOrder): Promise<any> {
    const {
      order_id,
      order_status,
      return_date,
      is_rto,
      payment_status,
      payment,
      order_items,
      courier_provider_id,
      tracking_id,
      refund_amount,
      return_order_manager,
    } = {
      ...return_order,
    }

    const return_order_detail = {
      order_id,
      order_status,
      return_date,
      is_rto,
      payment_status,
      courier_provider_id,
      tracking_id,
      refund_amount,
      return_order_manager,
    }

    const data = await axios.post(`${api_url}/return_order`, {
      data: {return_order_detail, payment, order_items},
    })

    // console.log('return order from supabase ->', data)

    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      return data.data
    } else {
      toast.error(data.data.message)
    }
  }

  async addFollowupCall(followup: any): Promise<FollowupCall | undefined> {
    try {
      const caller = localStorage.getItem('user_data')
      let caller_data = caller && JSON.parse(caller)

      let caller_id = caller_data.id

      const {data, error} = await supabase
        .from('followups')
        .update({
          note: followup.note,
          call_status: followup.call_status,
          status: 'completed',
          caller_id,
          updated_at: new Date(),
        })
        .eq('order_id', followup.order_id)
        .eq('status', 'pending')
        .single()

      if (error) {
        console.log('from add followup function update followup ->', error?.message)
        toast.error(error?.message)
        return
      }

      const {error: order_error} = await supabase
        .from('orders')
        .update({followup_date: followup.schedule_date})
        .eq('id', followup.order_id)
        .select('id')
        .single()

      if (order_error) {
        console.log(
          'from add followup function update schedule_date in order ->',
          order_error?.message
        )
        toast.error(order_error?.message)
        return
      }

      let followup_call: FollowupCall | null = {
        id: '',
        customer_id: '',
        caller_id: '',
        call_status: '',
        note: '',
        schedule_date: '',
        status: '',
      }
      let followup_call_error: any

      if (followup.schedule_date) {
        const {data: followup_data, error: followup_error} = await supabase
          .from('followups')
          .insert({
            schedule_date: followup.schedule_date,
            customer_id: followup.customer_id,
            order_id: followup.order_id,
            status: 'pending',
          })
          .select()
          .single()

        followup_call = followup_data
        if (followup_error) followup_call_error = followup_error
      } else {
        followup_call = data
      }
      if (followup_call) {
        toast.success('Next Followup Call Successfully Scheduled.')
        // console.log('data from add followup call ->', followup_call)
        return followup_call
      } else {
        toast.error(followup_call_error?.message)
        console.log('error from add followup call ->', followup_call_error)
      }
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async addFollowUpWithoutOrder(followup: any): Promise<any> {
    try {
      const caller = localStorage.getItem('user_data')
      let caller_data = caller && JSON.parse(caller)

      let caller_id = caller_data.id

      const {data, error} = await supabase
        .from('followups')
        .update({
          note: followup.note,
          call_status: followup.call_status,
          status: 'completed',
          caller_id,
          updated_at: new Date(),
        })
        .eq('id', followup.id)
        .eq('status', 'pending')
        .single()

      if (error) {
        console.log('from add followup function update followup ->', error?.message)
        toast.error(error?.message)
        return
      }
      let followup_call: FollowupCall | null = {
        id: '',
        customer_id: '',
        caller_id: '',
        call_status: '',
        note: '',
        schedule_date: '',

        status: '',
      }
      let followup_call_error: any

      if (followup.schedule_date) {
        const {data: followup_data, error: followup_error} = await supabase
          .from('followups')
          .insert({
            schedule_date: followup.schedule_date,
            customer_id: followup.customer_id,
            status: 'pending',
            order_id: null,
          })
          .select()
          .single()

        followup_call = followup_data
        if (followup_error) followup_call_error = followup_error
      } else {
        followup_call = data
      }
      if (followup_call) {
        toast.success('Next Followup Call Successfully Scheduled.')
        // console.log('data from add followup call ->', followup_call)
        return followup_call
      } else {
        toast.error(followup_call_error?.message)
        console.log('error from add followup call ->', followup_call_error)
      }
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getAllPendingFollowupCall(
    offset: number,
    itemsPerPage: number,
    date: string,
    value: string
  ): Promise<PendingCall[] | undefined> {
    try {
      let call_data: any = []
      let call_error: string = ''
      if (date === '' && value === '') {
        const {data, error} = await supabase
          .from('customer_followup_data')
          .select('*')
          .eq('is_dnd', false)
          .eq('status', 'pending')
          .order('schedule_date', {ascending: true})
          .range(offset, itemsPerPage)
        if (data) call_data = data
        else call_error = error.message
      } else if (date !== '' && value !== '') {
        const {data, error} = await supabase
          .from('customer_followup_data')
          .select('*')
          .eq('schedule_date', date)
          .eq('is_dnd', false)
          .eq('status', 'pending')
          .or(
            `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,tracking_id.ilike.%${value}%`
          )
          .order('schedule_date', {ascending: true})
          .range(offset, itemsPerPage)
        if (data) call_data = data
        else call_error = error.message
      } else if (date !== '') {
        const {data, error} = await supabase
          .from('customer_followup_data')
          .select('*')
          .eq('schedule_date', date)
          .eq('is_dnd', false)
          .eq('status', 'pending')
          .order('schedule_date', {ascending: true})
          .range(offset, itemsPerPage)
        if (data) call_data = data
        else call_error = error.message
      } else if (value !== '') {
        const {data, error} = await supabase
          .from('customer_followup_data')
          .select('*')
          .eq('is_dnd', false)
          .eq('status', 'pending')
          .or(
            `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,tracking_id.ilike.%${value}%`
          )
          .order('schedule_date', {ascending: true})
          .range(offset, itemsPerPage)
        if (data) call_data = data
        else call_error = error.message
      }

      if (call_data) {
        return call_data
      } else {
        console.log('error from getAllPendingFollowupCall ->', call_error)
        toast.error(call_error)
      }
    } catch (error) {
      console.log('internal server error from add followup call ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getTotalFollowupCalls(selectedDate: string, value: string): Promise<number | null> {
    let call_count: number | null = 0

    if (selectedDate === '' && value === '') {
      const {count} = await supabase
        .from('customer_followup_data')
        .select('*', {count: 'exact', head: true})
        .eq('is_dnd', false)
        .eq('status', 'pending')
      call_count = count
    } else if (selectedDate !== '' && value !== '') {
      const {count} = await supabase
        .from('customer_followup_data')
        .select('*', {count: 'exact', head: true})
        .eq('is_dnd', false)
        .eq('status', 'pending')
        .eq('schedule_date', selectedDate)
        .or(
          `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,tracking_id.ilike.%${value}%`
        )
      call_count = count
    } else if (selectedDate !== '') {
      const {count} = await supabase
        .from('customer_followup_data')
        .select('*', {count: 'exact', head: true})
        .eq('schedule_date', selectedDate)
        .eq('is_dnd', false)
        .eq('status', 'pending')
      call_count = count
    } else if (value !== '') {
      const {count} = await supabase
        .from('customer_followup_data')
        .select('*', {count: 'exact', head: true})
        .eq('is_dnd', false)
        .eq('status', 'pending')
        .or(
          `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,tracking_id.ilike.%${value}%`
        )
      call_count = count
    }

    // console.log('CALLS COUNT -->', call_count)

    return call_count
  }

  async getDNDAllCustomers(offset: number, itemsPerPage: number) {
    try {
      const {data, error} = await supabase
        .from('customers')
        .select('*')
        .eq('is_dnd', true)
        .range(offset, itemsPerPage)
        .order('dnd_on_at', {ascending: true})
      if (data) {
        // console.log('DND DATA ->', data)
      } else {
        console.log('error from get dnd customer ->', error)
        throw new Error(error.message)
      }
      return data
    } catch (error) {
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getTotalDNDCustomersCount() {
    const {count} = await supabase
      .from('customers')
      .select('*', {count: 'exact', head: true})
      .eq('is_dnd', true)
    // console.log('DND COUNT =>', count)

    return count
  }

  async addNewCustomer(customer: AddCustomer): Promise<any> {
    try {
      const {
        name,
        mobile,
        mobile_2,
        mobile_3,
        address,
        district,
        taluka,
        state,
        pincode,
        at_post,
        sales_man_id,
        created_by,
        city,
        village,
      } = {
        ...customer,
      }

      console.log('from supabase ->', sales_man_id)

      const {data: existingCustomers1} = await supabase
        .from('customers')
        .select('name,mobile, mobile_2, mobile_3')
        .or(`mobile.in.(${mobile},${mobile_2},${mobile_3})`)

      console.log('existingCustomers1 ->', existingCustomers1)

      const {data: existingCustomers2} = await supabase
        .from('customers')
        .select('name,mobile, mobile_2, mobile_3')
        .or(`mobile_2.in.(${mobile},${mobile_2},${mobile_3})`)
        .or(`mobile_3.is.null`)
        .or(`mobile_3.eq.' '`)

      console.log('existingCustomers2 ->', existingCustomers2)

      const {data: existingCustomers3} = await supabase
        .from('customers')
        .select('name,mobile, mobile_2, mobile_3')
        .or(`mobile_3.in.(${mobile},${mobile_2},${mobile_3})`)
        .or(`mobile_3.is.null`)
        .or(`mobile_3.eq.' '`)

      console.log('existingCustomers3 ->', existingCustomers3)

      let existingCustomers: any = []

      if (existingCustomers1) existingCustomers.push(...existingCustomers1)
      if (existingCustomers2) existingCustomers.push(...existingCustomers2)
      if (existingCustomers3) existingCustomers.push(...existingCustomers3)

      console.log('existingCustomers ->', existingCustomers)
      if (existingCustomers?.length !== 0) {
        toast.error('One of the entered mobile numbers is already registered.')
        return
      }

      const {data, error} = await supabase
        .from('customers')
        .insert({
          name,
          mobile,
          mobile_2: mobile_2,
          mobile_3: mobile_3,
          sales_person_id: sales_man_id,
          created_by,
        })
        .select()
        .single()
      // console.log('data from add new customer->', data)
      console.log('error from add new customer ->', error)
      toast.error(error?.message)
      if (error) return

      if (data) {
        const {error: address_error} = await supabase
          .from('customer_address')
          .insert({
            address,
            district,
            taluka,
            at_post,
            village,
            city,
            state,
            pincode,
            customer_id: data.id,
          })
          .select()
          .single()

        console.log('error from add customer address ->', address_error)
        toast.success(' Customer added successfully ')

        const {data: customer} = await supabase
          .from('customers')
          .select('*,customer_address(*),users!customers_sales_person_id_fkey(id,name)')
          .eq('id', data.id)
          .single()

        return {
          value: customer.id,
          label: customer.name,
          mobile: customer.mobile,
          created_at: customer.created_at,
          users: {value: customer.users.id, label: customer.users.name},
          customer_address: customer.customer_address,
        }
      }
      // else {
      //   toast.error(error?.message)
      // }
    } catch (error) {
      console.log('internal server error from get all users ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getAllCustomers(): Promise<CustomersType | null> {
    try {
      const {data, error} = await supabase
        .from('customers')
        .select(
          'id,name,mobile,mobile_2,mobile_3,created_at,customer_address(*),users!customers_sales_person_id_fkey(id,name)'
        )
      if (data) {
        const final_list = data.map((customer: any) => {
          // return {
          //   value: customer.id,
          //   label: customer.name,
          // }
          customer.value = customer.id
          customer.label = customer.name
          customer.users.value = customer.users.id
          customer.users.label = customer.users.name
          delete customer.users.id
          delete customer.users.name
          delete customer.name
          return customer
        })
        return final_list
      } else {
        toast.error(error.message)
        return null
      }
    } catch (error) {
      console.log('internal server error from get all users ->', error)
      //@ts-ignore
      toast.error(error.message)
      return null
    }
  }

  async editCustomerDetails(customer: EditCustomerDetail): Promise<any> {
    const {id, name, mobile, mobile_2, mobile_3, address_detail, sales_person_id} = {...customer}

    // const {data: existingCustomers1, error: fetchError} = await supabase
    //   .from('customers')
    //   .select('name,mobile, mobile_2, mobile_3')
    //   .or(`mobile.in.(${mobile},${mobile_2},${mobile_3})`)

    // console.log('existingCustomers1 ->', existingCustomers1)

    // const {data: existingCustomers2, error: fetchError2} = await supabase
    //   .from('customers')
    //   .select('name,mobile, mobile_2, mobile_3')
    //   .or(`mobile_2.in.(${mobile},${mobile_2},${mobile_3})`)
    //   .or(`mobile_3.is.null`)
    //   .or(`mobile_3.eq.' '`)

    // console.log('existingCustomers2 ->', existingCustomers2)

    // const {data: existingCustomers3, error: fetchError3} = await supabase
    //   .from('customers')
    //   .select('name,mobile, mobile_2, mobile_3')
    //   .or(`mobile_3.in.(${mobile},${mobile_2},${mobile_3})`)
    //   .or(`mobile_3.is.null`)
    //   .or(`mobile_3.eq.' '`)

    // console.log('existingCustomers3 ->', existingCustomers3)

    // let existingCustomers: any = []

    // if (existingCustomers1) existingCustomers.push(...existingCustomers1)
    // if (existingCustomers2) existingCustomers.push(...existingCustomers2)
    // if (existingCustomers3) existingCustomers.push(...existingCustomers3)

    // console.log('existingCustomers ->', existingCustomers)
    // if (existingCustomers?.length !== 0) {
    //   toast.error('One of the entered mobile numbers is already registered.')
    //   return
    // }

    const {data: update_customer, error: update_customer_error} = await supabase
      .from('customers')
      .update({name, mobile, mobile_2, mobile_3, sales_person_id})
      .eq('id', id)
      .select()
      .single()

    if (update_customer_error) {
      toast.error(update_customer_error.message)
      return
    }
    if (address_detail.id !== '') {
      const {id, address, district, taluka, state, pincode, at_post, city, village}: Address = {
        ...address_detail,
      }

      const {error: update_address_error} = await supabase
        .from('customer_address')
        .update({address, district, taluka, state, pincode, at_post, city, village})
        .eq('id', id)
        .select()
        .single()

      if (update_address_error) {
        toast.error(update_address_error.message)
        return
      }
    }

    return update_customer
  }

  async getAllProducts(): Promise<ProductsType | null> {
    try {
      const {data, error} = await supabase
        .from('products')
        .select('*')
        .order('name', {ascending: true})

      const productMap = data?.reduce((map, product: Product) => {
        map[product.id] = product
        return map
      }, {})

      // Iterate through the products to update the "products" property
      data?.forEach((product: Product) => {
        if (product.product_ids) {
          // Parse the product IDs from the "product_ids" string
          const productIds = JSON.parse(product.product_ids)

          // Extract the corresponding products and add them to the "products" property
          product.products = productIds.map((productId: string) => productMap[productId])
        }
      })

      if (data) {
        const final_list = data.map((prod: any) => {
          prod.value = prod.id
          prod.label = prod.name
          delete prod.id
          delete prod.name
          return prod
        })
        return final_list
      } else {
        toast.error(error.message)
        return null
      }
    } catch (error) {
      console.log('internal server error from get all users ->', error)
      //@ts-ignore
      toast.error(error.message)
      return null
    }
  }

  async addNewProduct(newProductObj: AddProduct): Promise<Product | undefined> {
    // async addNewProduct(newProductObj: AddProduct): Promise<any> {
    try {
      const {name, code, unit, unit_type, gst, description, price, image, product_ids, is_combo} = {
        ...newProductObj,
      }

      // console.log(image)
      let image_name: string | undefined = ''
      if (image) {
        const parts = image.name.split('.')
        const fileName = parts[0]
        const extension = parts[1]
        image_name = `${fileName.replace(/ /g, '_').toLowerCase()}-${Date.now()}.${extension}`
        const {data: image_data, error} = await supabase.storage
          .from('amar_company')
          .upload(`products/${image_name}`, image)
        image_name = image_data?.path
        console.log('from project image upload error ->', error)
        if (error) {
          toast.error(error.message)
          //@ts-ignore
        }
      }
      // if (image_name !== undefined || '') {
      let product: any
      let product_error: string | undefined
      const {data, error} = await supabase
        .from('products')
        .insert({
          name,
          code,
          unit,
          unit_type,
          gst,
          description,
          price,
          image: image_name,
          current_stock: 0,
          product_ids,
          is_combo,
        })
        .select()
        .single()
      if (data) product = data
      else throw new Error(error?.message)

      console.log('from add product error ->', product_error)
      if (product) {
        toast.success('Product added successfully')
        product.value = product.id
        product.label = product.name
        delete product.id
        delete product.name
        return product
      } else {
        toast.error(product_error)
      }
      // }
    } catch (error) {
      console.log('internal server error from add product ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async editProductDetails(product: EditProduct, id: string): Promise<Product | null> {
    const {data, error} = await supabase
      .from('products')
      .update({...product})
      .eq('id', id)
      .select()
      .single()

    if (data) {
      toast.success('Product successfully update.')
      return data
    } else {
      toast.error(error?.message)
      return null
    }
  }

  async manageProductsStock(product_id: string, inventory: string): Promise<Product | undefined> {
    // try {

    const data = await axios.post(`${api_url}/add_stock`, {
      data: {product_id, inventory},
    })

    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      console.log(data.data)
      return data.data.data
    } else {
      toast.error(data.data.message)
    }
  }

  async getAllUsers(): Promise<UsersType | null> {
    const {data, error} = await supabase.from('users').select('*')

    if (data) return data
    else {
      toast.error(error.message)
      return null
    }
  }

  async addNewUser(
    data: AddUser,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<any | undefined> {
    const user_data = await axios.post(`${api_url}/add_user`, data)

    // console.log('user data ->', data)

    if (user_data.data.statusCode === 201) {
      setIsLoading(false)
      toast.success(user_data.data.message)
      return user_data.data
    } else {
      setIsLoading(false)
      toast.error(user_data.data.message)
    }
  }

  async editUser(user: any): Promise<boolean> {
    const data: any = await axios.post(`${api_url}/update_user`, user)

    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      return true
    } else {
      toast.error(data.data.message)
      return false
    }
  }

  async get_users_by_role(name: string): Promise<RoleWiseUsers[] | undefined> {
    try {
      const {data: get_role} = await supabase
        .from('user_roles')
        .select('id,name')
        .eq('name', name)
        .single()

      const all_users: UsersType | null = await this.getAllUsers()

      let users: User[] = []

      if (all_users) {
        all_users.forEach((user: User) => {
          const roles = JSON.parse(user.role)

          if (roles.includes(get_role?.id)) users.push(user)
        })
      }

      if (users.length > 0) {
        const final_list = users.map((user: any) => {
          user.value = user.id
          user.label = user.name
          delete user.id
          delete user.name
          return user
        })
        return final_list
      }
    } catch (error) {
      console.log('internal server error from add user ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getUserRoles(): Promise<UserRoles[] | undefined> {
    try {
      const {data, error} = await supabase.from('user_roles').select('id,name')

      console.log('error from get user roles ->', error)

      if (data) {
        const final_list = data.map((user: any) => {
          user.value = user.id
          user.label = user.name
          delete user.id
          delete user.name
          return user
        })
        return final_list
      }
    } catch (error) {
      console.log('internal server error from get user roles ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async addCourierCompany(courier_company: AddCourierCompany): Promise<CourierCompany | undefined> {
    try {
      console.log('courier_company', courier_company)

      const {data, error} = await supabase
        .from('courier_providers')
        .insert({...courier_company})
        .select()
        .single()

      if (data) {
        const obj = {
          value: data.id,
          label: data.name,
          e_biller_id: data.e_biller_id,
          tracking_url: data.tracking_url,
          followup_days: data.followup_days,
        }
        toast.success('Courier company added Successfully.')
        return obj
      } else toast.error(error?.message)
    } catch (error) {
      console.log('internal server error from get user roles ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async addAccountType(type: string): Promise<AccountType | undefined> {
    try {
      const {data, error} = await supabase.from('account_type').insert({type}).select().single()

      if (data) {
        const obj = {
          value: data.id,
          label: data.type,
        }
        toast.success('Account company added Successfully.')
        return obj
      } else toast.error(error?.message)
    } catch (error) {
      console.log('internal server error from get user roles ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async getAllAccountTypes(): Promise<AccountType[] | undefined> {
    try {
      const {data, error} = await supabase.from('account_type').select('*').is('deleted_at', null)

      let account_list = data?.map((account: any) => {
        return {
          value: account.id,
          label: account.type,
        }
      })

      if (data) return account_list
      else toast.error(error.message)
    } catch (error) {
      console.log('internal server error from get user roles ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async sheetUpload(
    sheet_data: any,
    setShow: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<boolean> {
    try {
      const data = await axios.post(`${api_url}/upload_sheet`, sheet_data)

      toast.success(data.data.message)
      setShow(false)
      return true
    } catch (error: any) {
      toast.error(error.message)
      setShow(false)
      return false
    }
  }

  async getTrackingId(courier_provider_id: string): Promise<string> {
    const {data} = await supabase
      .from('bar_codes')
      .select('tracking_id')
      .eq('courier_provider_id', courier_provider_id)
      .eq('is_use', false)
      .limit(1)
      .single()

    // console.log('tracking id ->', data)
    // console.log('tracking id error->', error)
    if (data) return data.tracking_id
    else return ''
  }

  async getAllTrackingIds(courier_companies: any): Promise<CourierCompanyWithBarcode[]> {
    const {data} = await supabase.from('bar_codes').select('*')

    // const couriers = courier_companies.forEach((company:any)=>{
    //   return company.value
    // })

    const courier_company: any = {}

    data?.forEach((barcode: any) => {
      const {courier_provider_id, is_use} = barcode

      if (!courier_company[courier_provider_id]) {
        courier_company[courier_provider_id] = {
          courier_provider_id: courier_provider_id,
          total_barcode: 0,
          is_use: 0,
        }
      }

      courier_company[courier_provider_id].total_barcode++

      if (is_use) {
        courier_company[courier_provider_id].is_use++
      }
    })

    const companies = Object.values(courier_company)

    const companies_bar_codes = courier_companies.map((company: any) => {
      const company_barcode_count: any = companies.find(
        (barcode: any) => barcode.courier_provider_id === company.value
      ) || {
        total_barcode: 0,
        is_use: 0,
      }

      return {
        ...company,
        total_barcode: company_barcode_count.total_barcode,
        is_use: company_barcode_count.is_use,
      }
    })

    // console.log(companies_bar_codes)
    return companies_bar_codes
  }

  async getAllAddress(customer_id: string): Promise<any> {
    const {data, error} = await supabase
      .from('customer_address')
      .select('*')
      .eq('customer_id', customer_id)

    if (data) return data
    else toast.error(error.message)
  }

  async getOrderPayment(order_id: string, type: string): Promise<any> {
    const query = supabase
      .from('payment_history')
      .select('*,account_type(*)')
      .eq('order_id', order_id)

    switch (type) {
      case 'order':
        query.eq('is_return', false).is('deleted_at', null).order('created_at', {ascending: true})
        break
      case 'return':
        query.eq('is_return', true).is('deleted_at', null).order('created_at', {ascending: true})
        break
    }

    const {data, error} = await query

    if (data) return data
    else toast.error(error?.message)
  }

  async getLastFollowup(order_id: string): Promise<any> {
    const {data} = await supabase
      .from('followups')
      .select('*,caller:caller_id(name)')
      .eq('order_id', order_id)
      .eq('status', 'completed')
      .order('updated_at', {ascending: false})
      .limit(2)

    return data
  }

  async getCouriers(date: string, endDate: string): Promise<any> {
    // const start_date =new Date(date)
    // const end_date =

    const {data: courier_company} = await supabase.from('courier_providers').select('id,name')

    const orders_query = supabase
      .from('orders')
      .select(
        'courier_provider:courier_provider_id(name),return_courier_provider:return_courier_provider_id(name),order_date,return_date,cancel_at'
      )

    let return_orders: any
    let cancel_orders: any

    if (date && endDate) {
      orders_query.gte('order_date', date).lte('order_date', endDate)
      const {data: r_o_data} = await supabase
        .from('orders')
        .select('return_courier_provider:return_courier_provider_id(name),return_date')
        .gte('return_date', date)
        .lte('return_date', endDate)
      return_orders = r_o_data
      const {data: c_o_data} = await supabase
        .from('orders')
        .select('courier_provider:courier_provider_id(name),cancel_at')
        .gte('cancel_at', date)
        .lte('cancel_at', endDate)
      cancel_orders = c_o_data
    } else if (date) {
      orders_query.or(`order_date.eq.${date},return_date.eq.${date},cancel_at.eq.${date}`)
    }

    const {data: orders} = await orders_query

    // const {data: orders} = await supabase
    //   .from('orders')
    //   .select(
    //     '*,courier_provider:courier_provider_id(id,name),return_courier_provider:return_courier_provider_id(id,name)'
    //   )
    //   .gte('order_date', date)
    //   .lte('order_date', endDate)
    // .or(`order_date.gte.${date}, order_date.lte.${endDate}`)

    const result: any = {}
    courier_company?.forEach((company) => {
      result[company.name] = {orders: 0, return_orders: 0, cancel_orders: 0}
    })

    const s_date = new Date(date)

    if (!endDate) {
      orders?.forEach((order: any) => {
        const courierName = order.courier_provider && order.courier_provider.name

        let o_date: number = new Date(order.order_date).setUTCHours(0, 0, 0, 0)
        let r_date: Date | number | null = null
        let c_date: Date | number | null = null

        if (order.return_date) {
          r_date = new Date(order.return_date)
          let time = r_date.getTime()

          r_date = Math.floor(time / 86400000) * 86400000
        }

        if (order.cancel_at) {
          c_date = new Date(order.cancel_at)
          let time = c_date.getTime()

          c_date = Math.floor(time / 86400000) * 86400000
        }

        // console.log('o_date', o_date)
        // console.log('given date ->', s_date.getTime())
        // console.log('return date ->', r_date, 'cancel date ->', c_date)

        if (!endDate) {
          if (o_date === s_date.getTime() && courierName) result[courierName].orders++
          if (r_date && r_date === s_date.getTime() && courierName)
            result[courierName].return_orders++
          if (c_date && c_date === s_date.getTime() && courierName)
            result[courierName].cancel_orders++
        }
      })
    } else {
      orders?.forEach((order: any) => {
        const courierName = order.courier_provider && order.courier_provider.name
        if (courierName) result[courierName].orders++
      })

      return_orders.forEach((order: any) => {
        const courierName = order.return_courier_provider && order.return_courier_provider.name
        if (courierName) result[courierName].return_orders++
      })
      cancel_orders.forEach((order: any) => {
        const courierName = order.courier_provider && order.courier_provider.name
        if (courierName) result[courierName].cancel_orders++
      })
    }

    const response = Object.keys(result).map((courier) => ({
      [courier]: {
        orders: result[courier].orders,
        return_orders: result[courier].return_orders,
        cancel_orders: result[courier].cancel_orders,
      },
    }))

    response.sort((a: any, b: any) => b[Object.keys(b)[0]].orders - a[Object.keys(a)[0]].orders)

    // console.log(response)
    return response
  }

  async getCustomerAllDetail(mobile: string): Promise<any> {
    const {data, error} = await supabase
      .from('customers')
      .select(
        '*,sales_man:sales_person_id(name),orders(*,courier_provider_id(name),order_items(*,products(*))),customer_address(*),payment_history(*,order:orders(order_code),payment_method(type))'
      )
      .like('mobile', `${mobile}%`)
      .order('created_at', {foreignTable: 'orders', ascending: false})

    if (data) return data
    else console.log('from customer all detail ->', error)
    // console.log('data ->', data)
    // console.log('error ->', error)
  }

  async setDNDStatus(
    customer_id: string,
    is_dnd: boolean,
    schedule_date: string
  ): Promise<Customer | null> {
    console.log('dnd status ->', is_dnd)

    let customer_data: Customer | null = null

    if (is_dnd) {
      const {error: followup_error} = await supabase
        .from('followups')
        .update({status: 'cancel'})
        .lte('schedule_date', schedule_date)
        .eq('customer_id', customer_id)
        .is('call_status', null)

      if (followup_error) {
        toast.error(followup_error?.message)
        throw new Error(followup_error?.message)
        // return null
      }

      const {data: customer, error: customer_error} = await supabase
        .from('customers')
        .update({is_dnd, dnd_on_at: new Date()})
        .eq('id', customer_id)
        .select()
        .single()

      if (customer_error) {
        // toast.error(customer_error?.message)
        throw new Error(customer_error?.message)
      } else {
        customer_data = customer
      }
    } else {
      const {error: followup_error} = await supabase
        .from('followups')
        .update({status: 'pending'})
        .gte('schedule_date', schedule_date)
        .eq('customer_id', customer_id)
        .is('call_status', null)

      if (followup_error) {
        toast.error(followup_error?.message)
        throw new Error(followup_error?.message)
        // return null
      }

      const {data: customer, error: customer_error} = await supabase
        .from('customers')
        .update({is_dnd, dnd_off_at: new Date()})
        .eq('id', customer_id)
        .select()
        .single()
    }

    // let dnd_on_at: any = null
    // let dnd_off_at: any = null

    // dnd_on_at = is_dnd ? new Date() : null

    return customer_data
  }

  async addPayment(payment_obj: any): Promise<Payments | boolean> {
    let payment_data: Payments = {
      amount: 0,
      courier_provider_id: '',
      created_at: '',
      id: '',
      import_sheet_id: '',
      is_import: false,
      note: '',
      order_code: '',
      payment_method: '',
      transaction_id: '',
      is_return: false,
    }
    let payment_error_data: string | null = ''
    const {data: order} = await supabase
      .from('orders')
      .select('paid_amount,total_amount,refund_amount')
      .eq('id', payment_obj.order_id)
      .single()

    if (!payment_obj.is_return) {
      if (order?.total_amount === order?.paid_amount) {
        toast.error('Order amount all ready Paid.')
        return false
      }
      if (order) {
        if (order.paid_amount < order.total_amount) {
          console.log('IN IF')
          const {data: payment, error: payment_error} = await supabase
            .from('payment_history')
            .insert({...payment_obj})
            .select()
            .single()
          payment_data = payment
          console.log('error from payment_error ->', payment_error)
          if (payment) {
            const {data: update_paid_amount, error: update_paid_amount_error} = await supabase
              .from('orders')
              .update({paid_amount: order.paid_amount + payment_obj.amount})
              .eq('id', payment_obj.order_id)
              .select()
              .single()
          } else payment_error_data = payment_error && payment_error.message
        }
        if (order.paid_amount + payment_obj.amount === order.total_amount) {
          const {data: update_paid_amount, error: update_paid_amount_error} = await supabase
            .from('orders')
            .update({payment_status: 'Paid', order_status: 'Deliver'})
            .eq('id', payment_obj.order_id)
            .select()
            .single()
        }
      }
    }
    if (payment_obj.is_return) {
      const {data: order_data, error: order_error} = await supabase
        .from('orders')
        .update({
          refund_amount: payment_obj.refund_amount,
          payment_status: 'Refund',
          return_payment_method: payment_obj.payment_method,
        })
        .eq('id', payment_obj.order_id)
        .select()

      console.log('order ->', order_data)

      if (order_error) {
        toast.error(order_error?.message)
        return false
      }

      for (let item of payment_obj.order_items) {
        const {error} = await supabase
          .from('order_items')
          .update({refund_amount: item.refund_amount})
          .eq('id', item.id)
          .select()

        if (error) {
          toast.error(error.message)
          break
        }
      }

      const {data: payment, error: payment_error} = await supabase
        .from('payment_history')
        .insert({
          order_id: payment_obj.order_id,
          payment_method: payment_obj.payment_method,
          transaction_id: payment_obj.transaction_id,
          amount: payment_obj.amount,
          customer_id: payment_obj.customer_id,
          // payment_date: date,
          created_by: payment_obj.created_by,
          note: payment_obj.note,
          is_return: true,
        })
        .select()
        .single()
      if (payment) payment_data = payment
      else payment_error_data = payment_error && payment_error.message
    }

    if (payment_data.id) return payment_data
    else {
      toast.error(payment_error_data)
      return false
    }
    // const {data: order_update, error: order_update_error} = await supabase
    //   .from('orders')
    //   .update({payment_status: 'Paid'})
    //   .eq('id', payment_obj.order_id)
  }

  async deleteItemFromOrder(id: string): Promise<any> {
    const {data} = await supabase.from('order_items').select('*').eq('id', id).single()

    if (data) {
      const {error} = await supabase.from('order_items').delete().eq('id', id)

      if (error) return toast.error(error.message)
    }
  }

  async addOrderItem(product_id: string, order_id: string, price: number): Promise<any> {
    const {data, error} = await supabase
      .from('order_items')
      .insert({product_id, order_id, price, units: 0})
      .select('*,products(label:name,code,product_ids,image,is_combo)')
      .single()

    if (error?.message) toast.error(error.message)
    else {
      if (data.products.is_combo) {
      }
      const obj = {
        id: data.id,
        code: data.products.code,
        image: data.products.image,
        label: data.products.label,
        price: data.price,
        units: data.units,
        is_combo: data.products.is_combo,
        sub_total: data.sub_total,
        created_at: data.created_at,
        product_id: data.product_id,
        updated_at: data.updated_at,
        damage_unit: data.damage_unit,
        product_ids: data.products.product_ids,
        return_unit: data.return_unit,
        refund_amount: data.refund_amount,
      }
      return obj
    }
  }

  async deleteReturnOrder(order: any): Promise<any> {
    const data = await axios.post(`${api_url}/delete_return_order`, order)
    console.log('return order delete ->', data)
    if (data.status) {
      console.log('delete order data ->', data.data)
      return data.data.data
    }
    // console.log('delete order data ->', data)
  }

  async getAllLeaves(): Promise<Leave[] | null> {
    const {data, error} = await supabase.from('setting').select('*').eq('key', 'Leave')

    console.log(data)

    let leave_data: Leave[] = []
    if (data !== null && data[0].value !== '') leave_data = JSON.parse(data[0].value)

    // console.log(leave_data)
    if (data) {
      return leave_data
    } else {
      toast.error(error?.message)
      return null
    }
  }

  async addLeaves(leave: string): Promise<Leave[] | undefined> {
    const {data, error} = await supabase
      .from('setting')
      .update({value: leave})
      .eq('key', 'Leave')
      .select()
      .single()

    console.log('error from add leaves ->', error)
    if (error) toast.error(error.message)
    else {
      toast.success('Leave is Scheduled Successfully.')
      let leave_data: Leave[] = JSON.parse(data?.value)
      return leave_data
    }
  }

  async addCompany(company: AddCompany): Promise<Company | undefined> {
    const {name, address, mobile, gst, email, file} = {...company}

    let image_name: string | undefined = ''
    if (file) {
      const parts = file.name.split('.')
      const fileName = parts[0]
      const extension = parts[1]
      image_name = `${fileName.replace(/ /g, '_').toLowerCase()}-${Date.now()}.${extension}`
      const {data: image_data, error} = await supabase.storage
        .from('amar_company')
        .upload(`signature/${image_name}`, file)
      image_name = image_data?.path
      console.log('from project image upload error ->', error)
      if (error) {
        toast.error(error.message)
        //@ts-ignore
      }
    }

    console.log('company ->', company)
    if (image_name !== undefined || '') {
      const value = JSON.stringify({name, address, mobile, gst, email, image_name})
      const {data, error} = await supabase
        .from('setting')
        .update({value})
        .eq('key', 'Company')
        .select()
        .single()
      if (data) return JSON.parse(data.value)
      else toast.error(error?.message)

      // if (company_data) return company_data
    }
  }

  async getCompanyDetail(): Promise<Company | undefined> {
    const {data} = await supabase.from('setting').select('value').eq('key', 'Company').single()

    if (data?.value) {
      return JSON.parse(data.value)
    }
  }

  async getOrder(id: string): Promise<any> {
    const {data, error} = await supabase
      .from('orders')
      .select(
        '*,customers(*),sales_man:sales_person_id(id,name),order_courier_provider:courier_provider_id(id,name),return_courier_provider:return_courier_provider_id(id,name),customer_address(*),order_items(*,products(*)),order_payment_method:payment_method(id,type),return_payment:return_payment_method(id,type),payment_history(*)'
      )
      .eq('id', id)
      .single()

    if (data) {
      data.order_items.map((item: any) => {
        item.label = item.products.name
        item.code = item.products.code
        item.image = item.products.image
        item.gst = item.products.gst
        delete item.products
        return item
      })
      return data
    } else toast.error(error?.message)
  }

  async deleteAccountType(id: string): Promise<AccountType> {
    const {data, error} = await supabase
      .from('account_type')
      .update({deleted_at: new Date()})
      .eq('id', id)
      .select()
      .single()

    console.log('error ->', error)

    if (data) {
      toast.success('Account type successfully deleted .')
    }

    return {value: data.id, label: data.type}
  }

  async deleteCourierCompany(id: string): Promise<CourierCompany> {
    const {data} = await supabase
      .from('courier_providers')
      .update({deleted_at: new Date()})
      .eq('id', id)
      .select()
      .single()

    if (data) {
      toast.success('Account type successfully deleted .')
    }

    return {
      value: data.id,
      label: data.name,
      e_biller_id: data.e_biller_id,
      tracking_url: data.tracking_url,
      followup_days: data.followup_days,
    }
  }

  async editCourierCompany(courier: EditCourierCompany) {
    try {
      const {data, error} = await supabase
        .from('courier_providers')
        .update({...courier})
        .eq('id', courier.id)
        .select()
        .single()

      if (data) {
        toast.success('courier company updated successfully.')
        return data
      } else {
        toast.error(error?.message)
      }
    } catch (error) {
      console.log('internal server error from get user roles ->', error)
      //@ts-ignore
      toast.error(error.message)
    }
  }

  async cancelOrder(id: string, cancel_by: string): Promise<boolean> {
    const data = await axios.post(`${api_url}/cancel_order`, {id, cancel_by})
    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      return true
    } else {
      toast.error(data.data.message)
      return false
    }
  }

  async getAllCourierPayment(date: string, endDate: string): Promise<any> {
    const {data: courier_company} = await supabase.from('courier_providers').select('id,name')

    const query: any = supabase
      .from('payment_history')
      .select('amount,payment_method,courier_provider:courier_provider_id(name)')
      .is('is_return', false)

    const e_date = endDate ? new Date(endDate) : new Date(date)
    e_date.setDate(e_date.getDate() + 1)

    let end_date = e_date.toISOString().split('T')[0]

    if (date && endDate) {
      query.gte('created_at::date', date).lte('created_at::date', end_date)
    } else if (date) {
      query.gte('created_at::date', date).lte('created_at::date', end_date)
    }

    const {data: Payments} = await query

    const result: any = {}
    courier_company?.forEach((company) => {
      result[company.name] = {cod: 0, prepaid: 0}
    })

    Payments?.forEach((payment: any) => {
      if (payment.courier_provider) {
        const courierName = payment.courier_provider.name
        const isCod = payment.payment_method === null
        const codAmount = isCod && payment.amount
        const prePaidAmount = !isCod && payment.amount

        if (!result[courierName]) {
          result[courierName] = {cod: 0, prepaid: 0}
        }

        if (isCod) {
          result[courierName].cod += codAmount
        } else {
          result[courierName].prepaid += prePaidAmount
        }
      }
    })

    const response = Object.keys(result).map((courier) => ({
      [courier]: result[courier],
    }))

    // console.log(response)
    return response
  }

  async getLast25DaysPayments() {
    const current_date = new Date()

    let prev25Date: any = current_date.setDate(current_date.getDate() - 25)

    const prevDate = format(new Date(prev25Date), 'yyyy-MM-dd')

    const {data}: any = await supabase
      .from('orders')
      .select('total_amount')
      .lte('order_date', prevDate)
      .eq('payment_status', 'Pending')

    if (data) return data
  }

  async getPendingPaymentsCourierCompany(prev: string) {
    const {data}: any = await supabase
      .from('orders')
      .select('total_amount,courier_provider:courier_provider_id(name)')
      .lte('order_date', prev)
      .eq('payment_status', 'Pending')

    const courierTotals = data.reduce((acc: any, curr: any) => {
      const courierName = curr.courier_provider.name
      const totalAmount = curr.total_amount

      if (acc[courierName]) {
        acc[courierName] += totalAmount
      } else {
        acc[courierName] = totalAmount
      }

      return acc
    }, {})

    const result: any = Object.entries(courierTotals).map(([name, total]) => ({name, total}))

    result.sort((a: any, b: any) => b.total - a.total)

    return result
  }

  async getSearchCustomers(value: string): Promise<any> {
    const {data} = await supabase
      .from('customers')
      .select(
        'id,name,mobile,mobile_2,mobile_3,created_at,customer_address(*),users!customers_sales_person_id_fkey(id,name)'
      )
      .or(
        `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,name.ilike.%${value}%`
      )
      .limit(100)

    // data?.map((customer: any) => {
    //   const obj = {
    //     value: customer.id,
    //     label: customer.name,
    //     user: {label: customer.users.name, value: customer.users.mobile},
    //     ...customer,
    //   }

    //   delete obj.id
    //   delete obj.name

    //   return obj
    // })

    // console.log(data)

    if (data) return data
  }
  async getCustomers(
    offset: number,
    itemsPerPage: number,
    value: string
  ): Promise<CustomersType | null> {
    try {
      const {data, error} = await supabase
        .from('customers')
        .select(
          'id,name,mobile,mobile_2,mobile_3,created_at,customer_address(*),users!customers_sales_person_id_fkey(id,name),is_dnd'
        )
        .or(
          `mobile.like.%${value}%,mobile_2.like.%${value}%,mobile_3.like.%${value}%,name.ilike.%${value}%`
        )
        .range(offset, itemsPerPage)
      if (data) {
        const final_list = data.map((customer: any) => {
          // return {
          //   value: customer.id,
          //   label: customer.name,
          // }
          customer.value = customer.id
          customer.label = customer.name
          customer.users.value = customer.users.id
          customer.users.label = customer.users.name
          // eslint-disable-next-line no-self-assign
          customer.is_dnd = customer.is_dnd
          delete customer.users.id
          delete customer.users.name
          delete customer.name
          return customer
        })
        return final_list
      } else {
        toast.error(error.message)
        return null
      }
    } catch (error) {
      console.log('internal server error from get all users ->', error)
      //@ts-ignore
      toast.error(error.message)
      return null
    }
  }

  async getTotalCustomers(searchValue: string): Promise<number | null> {
    let query = supabase.from('customers').select('*', {count: 'exact', head: true})
    if (searchValue) {
      query.or(
        `mobile.like.%${searchValue}%,mobile_2.like.%${searchValue}%,mobile_3.like.%${searchValue}%,name.ilike.%${searchValue}%`
      )
    }
    const {count} = await query
    console.log('TOTAL COUNT-->', count)
    return count
  }

  async order_filter(filter_obj: any, page: number, type: string): Promise<any> {
    if (type === 'export') {
      const s_date = new Date(filter_obj.startDate)
      const e_date = filter_obj.endDate && new Date(filter_obj.endDate)

      const startDate = `${s_date.getFullYear()}-${s_date.getMonth() + 1}-${s_date.getDate()}`
      const endDate =
        e_date && `${e_date.getFullYear()}-${e_date.getMonth() + 1}-${e_date.getDate()}`

      const response = await axios.post(
        `${api_url}/order_multiple_filter`,
        {...filter_obj, page, type},
        {
          responseType: 'blob',
        }
      )
      const url = window.URL.createObjectURL(response.data)

      // Create a link element to trigger the download
      const a = document.createElement('a')
      a.href = url
      // a.download = `${startDate}-${endDate}_Orders.xlsx` // Set the desired file name
      a.download =
        startDate !== '' && endDate !== ''
          ? `${startDate}-${endDate}_Orders.xlsx`
          : startDate === '' && endDate === ''
          ? `${new Date().toLocaleDateString('en-GB')}_Orders.xlsx `
          : `${startDate}_Orders.xlsx`

      // Trigger a click event on the link to start the download
      a.click()

      // Clean up: Revoke the ob
    } else {
      const response = await axios.post(`${api_url}/order_multiple_filter`, {
        ...filter_obj,
        page,
        type,
      })
      return response.data.data
    }
  }

  async deletePayment(
    deletePayment: {id: string; order_id: string; amount: number},
    deleted_by: string
  ): Promise<any> {
    const {id, order_id, amount} = {...deletePayment}

    const {data: order, error: order_error} = await supabase
      .from('orders')
      .select('paid_amount')
      .eq('id', order_id)
      .single()

    const {error: payment_error} = await supabase
      .from('payment_history')
      .update({deleted_at: new Date(), deleted_by})
      .eq('id', id)

    if (payment_error) {
      console.log('error from delete payment during delete payment->', payment_error)
      return false
    }

    const paid_amount = order?.paid_amount - amount

    const {error: update_order_error} = await supabase
      .from('orders')
      .update({paid_amount: paid_amount, order_status: 'Pending', payment_status: 'Pending'})
      .eq('id', order_id)
      .select()
      .single()

    if (update_order_error) {
      console.log('error from delete payment during update order->', order_error)
      return false
    }

    return true
  }

  async setPincodeRelatedValue(updatedValue: string): Promise<any> {
    const {data, error} = await supabase
      .from('addresses')
      .select('pincode,taluka, district,state,village_locality_name, office_name')
      .eq('pincode', updatedValue)

    if (error) {
      console.log('error from pincode related value ->', error)
      // toast.error(error.message)
      return error
    } else {
      // console.log('Data', data)

      return data
    }
  }

  async editReturnOrder(order: any): Promise<boolean> {
    const data: any = await axios.post(`${api_url}/edit_return_order`, order)
    if (data.data.statusCode === 200) {
      toast.success(data.data.message)
      return true
    } else {
      toast.error(data.data.message)
      return false
    }
  }

  async deleteReturnPayment(payment: any, deleted_by: string): Promise<any> {
    console.log('Payment -->', payment)

    const {data} = await supabase
      .from('orders')
      .select('refund_amount')
      .eq('id', payment.order_id)
      .single()
    console.log('REFUND AMOUNT', data)
    let newAmount: number = 0
    if (data) {
      newAmount = data.refund_amount - payment.amount
    }

    const {error: order_error} = await supabase
      .from('orders')
      .update({refund_amount: newAmount, payment_status: 'Pending'})
      .eq('id', payment.order_id)
      .single()

    if (order_error) {
      toast.error(order_error.message)
      return
    }

    const {error: payment_error} = await supabase
      .from('payment_history')
      .update({deleted_at: new Date(), deleted_by})
      .eq('id', payment.id)

    if (payment_error) {
      console.log('error from delete refund payment during delete payment->', payment_error)
      toast.error(payment_error.message)
      return false
    } else {
      return true
    }
  }

  async getAllPaymentSheets(): Promise<any> {
    const {data, error} = await supabase
      .from('import_sheets')
      .select('*,courier_provider_id(name)')
      .eq('sheet_type', 'payment')
      .order('date', {ascending: false})

    if (data) return data
    else toast.error(error.message)
  }

  async getAllExpense(offset: number, display: number): Promise<any> {
    const {data} = await supabase.from('expense_data').select('*').range(offset, display)

    return data
  }

  async getTotalExpanse() {
    const {count} = await supabase.from('expense_data').select('*', {count: 'exact', head: true})
    return count
  }

  async getExpenseById(tracking_id: string): Promise<any> {
    const {data} = await supabase
      .from('expenses')
      .select('type,amount')
      .eq('tracking_id', tracking_id)

    let obj: any = {
      cod: 0,
      booking: 0,
      other: 0,
      total: 0,
    }

    if (data && data.length > 0) {
      data?.forEach((exp: any) => {
        switch (exp.type) {
          case 'COD':
            obj.cod = exp.amount
            break
          case 'Booking':
            obj.booking = exp.amount
            break
          case 'Other':
            obj.other = exp.amount
            break
        }
      })

      obj.total = obj.cod + obj.booking + obj.other

      return obj
    } else {
      toast.error('No Record Found')
    }
  }

  async complete_followup_call(order_id: string): Promise<any> {
    console.log('order from function->', order_id)
    const {data: followup_call, error: followup_error} = await supabase
      .from('followups')
      // .select('*')
      // .eq('order_id', order_id)
      // .eq('status', 'pending')
      .update({status: 'cancel'})
      .eq('order_id', order_id)
      .eq('status', 'pending')
      .select('id')

    if (followup_error) {
      toast.error(followup_error?.message)
      return false
    }

    const {data: order, error: order_error} = await supabase
      .from('orders')
      // .select('*')
      // .eq('id', order_id)
      .update({followup_date: null})
      .eq('id', order_id)
      .select('order_code')
      .single()

    if (order_error) {
      toast.error(order_error?.message)
      return false
    }

    toast.success('Followup is Completed for this Order')
    return true
  }

  async complete_call(followup_id: string): Promise<any> {
    const {data: order, error: order_error} = await supabase
      .from('followups')
      // .select('*')
      // .eq('id', order_id)
      .update({status: 'completed'})
      .eq('id', followup_id)
      .single()

    if (order_error) {
      toast.error(order_error?.message)
      return false
    }

    toast.success('Followup is Completed for this Order')
    return true
  }

  async reports(
    start_date: string,
    end_date: string,
    type: string | null,
    product_ids: string[],
    sales_man_ids: string[],
    courier_provider_ids: string[],
    setLoader: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    try {
      const response = await axios.post(
        `${api_url}/reports`,
        {
          start_date: start_date,
          end_date: end_date,
          type: type,
          product_ids: product_ids,
          sales_man_ids: sales_man_ids,
          courier_provider_ids: courier_provider_ids,
        },
        {
          responseType: 'blob',
        }
      )
      if (response) setLoader(false)

      const url = window.URL.createObjectURL(response.data)

      const a = document.createElement('a')
      a.href = url
      a.download =
        start_date !== '' && end_date !== ''
          ? `${start_date}-${end_date}_${type}.xlsx`
          : `${start_date}_${type}.xlsx`

      a.click()

      window.URL.revokeObjectURL(url)
    } catch (error: any) {
      toast.error(error.message)
      setLoader(false)
    }
  }

  async returnOrderSheets(): Promise<any> {
    const {data, error} = await supabase
      .from('import_sheets')
      .select('*')
      .eq('sheet_type', 'return')
      .order('created_at', {ascending: false})

    if (data) return data
    else toast.error(error.message)
  }
}
export const supabaseData = new SupabaseMethods()
